import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_PARENT_CATEGORIES_SAGA, ILoadParentCategoriesActionSaga,
    LOAD_CATEGORIES_SAGA, ILoadCategoriesActionSaga
} from "./types";
import { fetchParentCategoriesList, fetchCategoriesList } from "../../api/category";
import {
    loadParentCategoriesProps, loadCategoriesProps, updateIsLoadingCategoriesProps
} from "../../reducers/categories/types"

function* loadParentCategoriesData(action: ILoadParentCategoriesActionSaga) {
    try {
        yield put(updateIsLoadingCategoriesProps({ isLoadingCategories: true, errorMessage: undefined })
        );
        const parentCategories = yield call(fetchParentCategoriesList,action.family_id);
        yield put(loadParentCategoriesProps({ parentCategories }))
        yield put(updateIsLoadingCategoriesProps({ isLoadingCategories: false, errorMessage: undefined })
        );
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingCategoriesProps({ isLoadingCategories: false, errorMessage: undefined })
        );
    }
}
export function* watchLoadParentCategories() {
    yield takeLatest(LOAD_PARENT_CATEGORIES_SAGA, loadParentCategoriesData)
}

function* loadCategoriesData(action: ILoadCategoriesActionSaga) {
    try {
        yield put(updateIsLoadingCategoriesProps({ isLoadingCategories: true, errorMessage: undefined })
        );
        const categories = yield call(fetchCategoriesList, action.parentCategoryId);
        yield put(loadCategoriesProps({categories}))
        yield put(updateIsLoadingCategoriesProps({ isLoadingCategories: false, errorMessage: undefined })
        );
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingCategoriesProps({ isLoadingCategories: false, errorMessage: undefined })
        );
    }
}
export function* watchLoadCategories() {
    yield takeLatest(LOAD_CATEGORIES_SAGA, loadCategoriesData)
}
export default [
    watchLoadParentCategories(),
    watchLoadCategories()

]