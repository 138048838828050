import { axiosInstance } from "../store";
import { handlStatusCodeMessage, logSecretly } from "../helpers";
import { IOrder, IPromocode, IPromotion, IReason } from "../dtos/IOrder";
import { notification } from "antd";
import { startProcess } from "./camunda/process";
import { QuotationToOrder } from "./quotation";

export function fetchOrderStatus(): Promise<any> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/order/code_list/filter_status`)
    .then((res) => (res.status == 200 ? res.data : []))
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response.status, entity: "order" });
      return [];
    });
}

export function fetchOrders(
  page = 0,
  size = 25,
  sort = "",
  orderId = "",
  firstName = "",
  lastName = "",
  email = "",
  mobile = "",
  dateFrom = "",
  dateTo = "",
  status = "",
  userId = "",
  trackingNumber = "",
  excluded = "",
  has_nfol = ""
): Promise<{ orders?: IOrder[]; totalOrdersCount?: number }> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}/order/order?page=${page}&size=${size}&sort=${sort}&order_id=${orderId}&first_name=${firstName}&last_name=${lastName}&customer_email=${email}&customer_mobile=${mobile}&date_from=${dateFrom}&date_to=${dateTo}&status=${status}&user_id=${userId}&tracking_number=${trackingNumber}&status.neq=${excluded}&has_nfol=${has_nfol}`
    )
    .then((res) => {
      if (res.status == 200) {
        return {
          orders: res.data,
          totalOrdersCount: parseInt(res.headers["x-total-count"]),
        };
      } else {
        return { orders: [], totalOrdersCount: 0 };
      }
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response.status, entity: "order" });
      return { orders: [], totalOrdersCount: 0 };
    });
}

export function fetchOrdersByTracking(
  data: any,
  page: any,
  size: any,
  sort: any
): Promise<{ orders: IOrder[], totalOrdersCount?: number }> {
  return axiosInstance.get(`${process.env.REACT_APP_API_URL}/shipment/shipment-order/orders?shipment_id=${data.tracking}&courier_id=${data.courier}&page=${page}&size=${size}&sort=${sort}`).then((res) => {
    if (res.status == 200) {
      return {
        orders: res.data,
        totalOrdersCount: parseInt(res.headers["x-total-count"]),
      };
    } else {
      return { orders: [], totalOrdersCount: 0 };
    }
  })
}

export function fetchNFOrders(
  page = 0,
  size = 25,
  sort = "",
  excluded = "",
): Promise<{ orders?: IOrder[]; totalOrdersCount?: number }> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}/order/order?has_nfol=true&not_found_informed_by_cs_by_status=pending&page=${page}&size=${size}&sort=${sort}&status.neq=5`
    )
    .then((res) => {
      if (res.status == 200) {
        return {
          orders: res.data,
          totalOrdersCount: parseInt(res.headers["x-total-count"]),
        };
      } else {
        return { orders: [], totalOrdersCount: 0 };
      }
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response.status, entity: "order" });
      return { orders: [], totalOrdersCount: 0 };
    });
}

export function fetchNFOrderLines(
  page = 0,
  size = 25,
): Promise<{ orders?: IOrder[]; totalOrdersCount?: number }> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}/order/order-line?status=1&page=${page}&size=${size}`
    )
    .then((res) => {
      if (res.status == 200) {
        return {
          orders: res.data,
          totalOrdersCount: parseInt(res.headers["x-total-count"]),
        };
      } else {
        return { orders: [], totalOrdersCount: 0 };
      }
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response.status, entity: "order" });
      return { orders: [], totalOrdersCount: 0 };
    });
}

export function fetchOrder(orderId: number, subjectId: string): Promise<any> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/order/order/${orderId}?subject_id=${subjectId}`)
    .then((res) => {
      if (res.status == 200) {
        return res.data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      return null;
    });
}

export function fetchOrderItems(order_id: number, page = 0, isDeleted = false): Promise<any> {
  return axiosInstance.get(`${process.env.REACT_APP_API_URL}/order/order/${order_id}/products?page=${page}&size=1000&deleted=${isDeleted}`)
    .then((res) => {
      if (res.status == 200) {
        return { data: res.data, totalOrderItemsCount: parseInt(res.headers['x-total-count']), currPage: page }
      }
      else {
        return { data: [], totalOrderItemsCount: 0, currPage: 0 }

      }
    })
    .catch((err) => {
      return { data: [], totalOrderItemsCount: 0, currPage: 0 }
    })
}
export function putStaffComments(
  orderId: number | undefined,
  comments: string
) { return axiosInstance.post(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/staff_comments`, { staff_comments: comments, }).then((res) => { return res }).catch((err) => { }) }

export function putTracking(orderId: number | undefined, comments: string) {
  return axiosInstance.post(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/tracking_number`, { tracking_number: comments, }).then((res) => { return res }).catch((err) => { })
}

export function rfpOrder(orderId: number | undefined) {
  return axiosInstance.post(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/ready-for-picking`)
    .then((res) => { return res }).catch((err) => { });
}

export function packOrder(orderId: number | undefined) {
  return axiosInstance.post(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/packed`)
    .then((res) => { return res }).catch((err) => { });
}

export function shipOrder(
  orderId: number | undefined,
  data: {
    trackingNumber: string;
    courier: string;
    agent?: string;
  }
) {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/ship`, {
      tracking_number: data.trackingNumber,
      courier: data.courier,
      agent: data.agent,
    }).then((res) => { return res }).catch((err) => { });
}
export function shippingOrder(orderId: number | undefined) {
  return axiosInstance
    .put(`${process.env.REACT_APP_API_URL}/order/order/change_status?target_status=4`, { order_ids: [orderId] })
    .then((res) => { return res }).catch((err) => { });
}
export function completeOrder(orderId: number | undefined) {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/complete`)
    .then((res) => { return res }).catch((err) => { });
}
export function cancelOrder(orderId: number | undefined) {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/cancel`)
    .then((res) => { return res }).catch((err) => { });
}
export function reship(orderId: number) {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/reship`)
    .then((res) => { return res }).catch((err) => { });
}

export function cancellationInProgress(orderId: number | undefined, cancellationReason: any) {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/cancellation-in-progress`, cancellationReason)
    .then((res) => { return res }).catch((err) => { });
}

export function fetchList(key: string): Promise<any> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/order/code_list/${key}`)
    .then((res) => { return res.data }).catch((err) => { return [] });
}

export function fetchPromotions(orderId: number): Promise<IPromotion[]> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/promotions`)
    .then((res) => { return res.data }).catch((err) => { return [] });
}

export function fetchPromocodes(orderId: number): Promise<IPromocode[]> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/order/order/${orderId}/promo_code`)
    .then((res) => { return res.data }).catch((err) => { return [] });
}

export function fetchCancellationReasons(): Promise<IReason[]> {
  return axiosInstance.get(`${process.env.REACT_APP_API_URL}/order/cancellation_reasons?is_active=1`).then((res) => { return res.data }).catch((err) => { return [] });
}

export function fetchEcommerceProfile(subject_id: string): Promise<any> {
  return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/profile/${subject_id}/type/ecommerce?component_id=4`)
    .then((res) => { return res.data }).catch((err) => { return undefined })
}

export function createOrder(order: any, subject_id: string, quotation_id?: number): Promise<any> {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}/order/order/save-and-submit`, order)
    .then((res) => {
      if (res.status == 200) {
        if (quotation_id) {
          QuotationToOrder(quotation_id, res.data.order_id).then((res) => {

          })
        }
        if (res.data.status == "11") {
          if (!["2", "9"].includes(localStorage.getItem("storefront"))) {
            let constructedObj = { order_id: res.data.order_id, subject_id: order.user_id }
            let processData = {
              process_definition_key: "Need_Approval",
              business_key: "",
              creator: subject_id,
              process_variables: { ...constructedObj },
            }
            startProcess(subject_id, processData, false).then((res: any) => {
              notification.success({ placement: "topRight", message: `Process Started with Process Id = ${res.proc_instance_id}` });
            });
          }
          notification.warning({ placement: "topRight", message: "Need Approval!" });
          return true;
        } else if (res.data.status == "10") {
          notification.warning({ placement: "topRight", message: "Order saved as Draft" });
          return true;
        } else {
          handlStatusCodeMessage({ code: res.status, entity: "cerate_order" });
          return true;
        }
      } else {
        handlStatusCodeMessage({ code: res.status, entity: "order" });
        return false;
      }
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response.status, entity: "order" });
      return false;
    }).finally();
}

export function submitNotFound(orderID: string, products: { "nfList": any[] }): Promise<any> {
  return new Promise((resolve, reject) => {
    axiosInstance.post(`${process.env.REACT_APP_API_URL}/order/order-line/${orderID}/submit-NF`, products)
      .then((res: any) => {
        resolve(res.data)
      }).catch((err: any) => {
        notification.error({ placement: "topRight", message: `${err.response.data.errorMessage ? err.response.data.errorMessage : "Failed"} and NF products not submitted yet!` });
        reject();
      })
  })
}

export function changeOrderLineStatus(ids: any, orderLine: any): Promise<any> {
  return axiosInstance.post(`${process.env.REACT_APP_API_URL}/order/order-line/${ids.order_id}/${ids.product_id}/${ids.vendor_id}/NFOL`, orderLine)
    .then((res) => {
      if (res.status == 200) {
        handlStatusCodeMessage({ code: res.status, entity: 'Order line status' });
        return res.data;
      }
      else {
        handlStatusCodeMessage({ code: res.status, entity: 'Order line status' });
        return null;
      }
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response.status, entity: 'Order line status' });
      return null;
    });
}

export function changeOrderStatus(order_id: number): Promise<any> {
  return axiosInstance.post(`${process.env.REACT_APP_API_URL}/order/order/${order_id}/not-found-action`)
    .then((res) => {


    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response.status, entity: 'Order line status' });
      return null;
    });
}

export function exportOrderPdf(order_id: number): Promise<any> {
  return axiosInstance.get(`${process.env.REACT_APP_API_URL}/order/document/order/${order_id}/export-pdf`, {
    responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/pdf',
      'Content-Disposition': 'filename',
    }
  }).then(res => {
    const file = new Blob([res.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);

    const pdfWindow = window.open();
    if (pdfWindow) {
      pdfWindow.location.href = fileURL;
    }
  })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response.status, entity: 'Order line status' });
      return null;
    });
}
export function fetchOrderHistory(orderId: string, page = 0, size = 25, sort = "",): Promise<any> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/order/order_audit?order_id=${orderId}&page=${page}&size=${size}&sort=${sort}`)
    .then((res) => {
      if (res.status == 200) {
        return {
          historyActions: res.data,
          totalHistoryActionsCount: parseInt(res.headers["x-total-count"]),
        };
      } else {
        return { historyActions: [], totalHistoryActionsCount: 0 };
      }
    }).catch((err) => { return [] });
}
